<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <!--
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>
            -->

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <!--
              <v-img
                contain
                max-width="60%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
              -->
              <v-img
                contain
                max-width="60%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/company_list_background.png`)"
              ></v-img>
            </div>
          </div>          
        </v-col>
        <v-col
          lg="4"
          md="12" 
          class="d-flex justify-center"                   
        >
          <v-card 
            class="py-4 pl-2 pr-2 mt-4 d-flex flex-column"
            max-height="1000"
          >
            <v-card-title>เลือกธุรกิจ</v-card-title>
            <v-card-subtitle>ที่ต้องการใช้งาน โดยสามารถสลับธุรกิจที่ต้องการได้ทุกเมื่อ</v-card-subtitle>

            <template v-if="loading">
              <v-skeleton-loader
                class="py-2"                
                type="list-item-avatar"              
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="py-2"                
                type="list-item-avatar"              
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="py-2"                
                type="list-item-avatar"              
              ></v-skeleton-loader>
            </template>
            <div v-else-if="!loading && companies.length == 0" class="text-center">
              <h2>ไม่พบกิจการ</h2>
            </div>
            <div v-else              
              class="d-flex flex-grow-1"
            >
              <v-card-text>
                <v-col>
                  <v-row v-for="(company, index) in companies" :key="'company_' + index"  @click="$router.push({ path: '/c/'+ company.id + '/home' })">
                    <v-card class="company-info-wrapper py-2 px-6 d-flex">                    
                      <div class="d-flex image-container align-center">
                        <CompanyLogo
                          :src="company.profile"
                          :value="company.name"
                          tile
                          rounded
                          size="60"
                          class="white--text"
                        />
                      </div>
                      <div class="d-flex flex-column info-container align-start justify-center">
                        <div>{{ company.name }}</div>
                        <div>สิทธิ์ : {{ company.owner ? "เจ้าของกิจการ":"พนักงาน" }}</div>        
                      </div>                            
                    </v-card>                  
                  </v-row>
                </v-col>              
              </v-card-text>
            </div>
            <div class="d-flex align-center justify-center mt-a">
              เพิ่มธุรกิจ ? <router-link :to="{name: 'new-company'}">สร้างใหม่</router-link>
            </div>
          </v-card>
        </v-col>        
      </v-row>      
    </div>
  </div>
</template>

<script>
import { asyncGet } from '../helpers/asyncAxios'
export default {
  data() {
    return {
      loading: false,
      companies: [],
    }
  },
  async created() {
    this.loading = true
    try {
      const response = await asyncGet('/company/list')
      const { companies } = response
      this.companies = companies
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }

    this.loading = false
  },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.company-info-wrapper {
  width: 100%;
  min-height: 120px;
}

.company-info-wrapper > .image-container {
  width: 120px;
}

.company-info-wrapper > .info-container {
  width: 100%;
}

.company-info-wrapper:hover {
  background-color: #d84c52;
  cursor: pointer;
}

.company-info-wrapper:hover > .info-container {
  color: #ffffff;
}
</style>
